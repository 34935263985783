<template>
  <div>
    <b-card-actions
      ref="reviewersCard"
      :title="statusTitle"
      action-refresh
      action-collapse
      no-body
      :show-loading="loading"
      :collapsed="collapsed"
      class="card-app-design"
      @refresh="refresh"
    >
      <!-- Review information -->
      <div style="padding: 0 1.5rem">
        <b-card-text class="mb-2">
          <b-badge :variant="resolveReviewStatusVariant(reviewData.status)" class="mr-50 mt-25">
            {{ reviewData.status }}
          </b-badge>
          <span class="font-small-2">
            Created {{ reviewData.created | diffForHumans }} by
            <b-avatar
              size="20"
              variant="light-secondary"
              class="cursor-pointer ml-25"
            >
              <feather-icon icon="UserIcon" />
            </b-avatar>
            <span id="review-created-by" class="font-weight-bolder ml-25 select-all">{{ getUserUsername(reviewData.created_by) }}</span>
          </span>
          <b-button
            v-if="reviewData.status === 'CLOSED' && userReviewStatus"
            variant="flat-warning"
            class="float-right ml-50"
            @click="actionReview('Open')"
          >
            Open Review
          </b-button>
          <b-button
            v-if="reviewData.status !== 'CLOSED' && userReviewStatus"
            variant="flat-warning"
            class="float-right ml-50"
            @click="actionReview('Close')"
          >
            Close Review
          </b-button>
          <!-- Approve/Reject Buttons -->
          <b-button-group
            v-if="userReviewStatus && reviewData.status !== 'CLOSED'"
            class="w-25 float-right"
          >
            <b-button
              v-b-tooltip.hover.top="'Reject this Review'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="userReviewStatus === 'rejected' ? 'danger' : 'outline-danger'"
              :disabled="reviewData.status === 'FINALISED'"
              @click="$emit('update-review', { action: 'decision', decision: 'rejected' })"
            >
              <span class="text-nowrap">
                <feather-icon icon="ThumbsDownIcon" class="mr-25" />
              </span>
            </b-button>
            <b-button
              v-b-tooltip.hover.top="'Approve this Review'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="userReviewStatus === 'approved' ? 'success' : 'outline-success'"
              :disabled="reviewData.status === 'FINALISED'"
              @click="$emit('update-review', { action: 'decision', decision: 'approved' })"
            >
              <span class="text-nowrap">
                <feather-icon icon="ThumbsUpIcon" class="mr-25" />
              </span>
            </b-button>
          </b-button-group>
        </b-card-text>

        <b-row>
          <b-col>
            <div class="design-group">
              <h6 class="section-label">
                <span class="font-weight-bold">{{ reviewData.reviewers.length }}</span> {{ reviewType === 'Review' ? 'Reviewer' : 'Approver' }}{{ reviewData.reviewers | handlePluralSuffix }}
              </h6>
              <b-avatar
                v-for="item in reviewData.reviewers"
                :key="item.reviewer"
                v-b-tooltip.hover.top="getUserUsername(item.reviewer)"
                :text="getUserUsername(item.reviewer.slice(0, 1))"
                size="30"
                class="mr-25 bg-gradient-info cursor-pointer"
              />
              <div class="d-inline" @click="showReviewerTable = !showReviewerTable">
                <b-avatar
                  v-if="reviewData.status !== 'FINALISED' && isUserReviewer"
                  id="btnAddReviewer"
                  v-b-tooltip.hover.right="'Request review from...'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="30"
                  variant="light-success"
                  class="cursor-pointer"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                </b-avatar>
              </div>
            </div>
          </b-col>
          <b-col
            sm="4"
            class="d-flex justify-content-between justify-content-sm-end align-items-center mb-2"
          >
            <b-link class="text-body text-nowrap" @click="showReviewerTable = !showReviewerTable">
              <span
                class="mr-25 text-primary font-weight-bold"
              >
                {{ showReviewerTable ? 'Hide' : 'Show' }} reviewer details
              </span>
              <feather-icon
                icon="ListIcon"
                size="20"
                class="profile-icon mr-50 mb-25 text-primary"
              />
            </b-link>
          </b-col>
        </b-row>
      </div>
      <!-- ./Review information -->

      <!-- Reviewers table -->
      <div v-if="showReviewerTable">
        <b-card-body class="pt-0">
          <div class="d-flex justify-content-between flex-wrap">
            <!-- Sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="1"
              label-for="sortBySelect"
              label-class="mt-50 mr-50 pl-0"
              class="mb-0 text-nowrap"
              style="min-width: 20rem"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
            <!-- ./Sorting  -->

            <!-- Add Reviewer -->
            <b-form
              v-if="reviewData.status !== 'FINALISED' && (isUserReviewer || isUserOwner)"
              class="pl-1 d-flex flex-wrap"
              @submit.prevent
            >
              <b-form-group class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    ref="username"
                    v-model="newReviewer"
                    type="text"
                    :placeholder="`Add user to review`"
                    @keydown.enter="addReviewer"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-success"
                      size="sm"
                      @click="addReviewer"
                    >
                      <feather-icon icon="PlusIcon" />
                      Add
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-form>
            <!-- ./Add Reviewer  -->

            <!-- Search -->
            <b-form-group
              label="Search"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              label-class="mt-50"
              class="mb-0"
              style="width: 30rem"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- ./Search -->
          </div>
        </b-card-body>

        <b-table
          responsive
          striped
          hover
          show-empty
          class="position-relative"
          :items="reviewData.reviewers"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <!--  Have the name display N/A if there is no name saved    -->
          <template #cell(decision)="data">
            <b-badge
              v-if="data.value === 'pending'"
              pill
              variant="secondary"
              class="text-capitalize"
            >
              {{ data.value }}
            </b-badge>
            <b-badge
              v-if="data.value === 'approved'"
              pill
              variant="success"
              class="text-capitalize"
            >
              {{ data.value }}
            </b-badge>
            <b-badge
              v-if="data.value === 'rejected'"
              pill
              variant="danger"
              class="text-capitalize"
            >
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <span
              v-if="reviewData.status !== 'FINALISED' && (isUserOwner || data.item.reviewer === $store.state.auth.id)"
              class="text-danger"
              @click="deleteReviewer(data.item.reviewer)"
            >
              <feather-icon
                icon="TrashIcon"
                class="cursor-pointer text-danger"
              />
            </span>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- Page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-class="mt-50"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
          <!-- ./Page length -->

          <!-- Pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="filter !== null ? filteredRows : totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
          <!-- ./Pagination -->
        </b-card-body>
      </div>

      <div>
        <b-card-body class="pt-0">
          <h5>Review comments</h5>
          <comment-card
            :parent-label="reviewType"
            :parent-id="reviewData.id"
            class="mt-1"
          />
        </b-card-body>
      </div>
      <!-- ./Reviewers table -->
      <!-- Actions Section     -->
      <b-col
        sm="4"
        class="d-flex float-right justify-content-sm-end align-items-center mb-2"
      >
        <b-link class="text-body text-nowrap" @click="showActionTable = !showActionTable">
          <span
            class="mr-25 text-primary font-weight-bold"
          >
            {{ showActionTable ? 'Hide' : 'Show' }} action details
          </span>
          <feather-icon
            icon="ListIcon"
            size="20"
            class="profile-icon mr-50 mb-25 text-primary"
          />
        </b-link>
      </b-col>
      <div v-if="showActionTable">
        <b-card-body class="pt-0">
          <div class="d-flex justify-content-between flex-wrap">
            <!-- Sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="1"
              label-for="sortBySelect"
              label-class="mt-50 mr-50 pl-0"
              class="mb-0 text-nowrap"
              style="min-width: 20rem"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="actionSortBy"
                  :options="actionSortOptions"
                >
                  <template #first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="actionSortDesc"
                  size="sm"
                  :disabled="!actionSortBy"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
            <!-- ./Sorting  -->
            <!-- Search -->
            <b-form-group
              label="Search"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              label-class="mt-50"
              class="mb-0"
              style="width: 30rem"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="actionFilter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!actionFilter"
                    @click="actionFilter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- ./Search -->
          </div>
        </b-card-body>
        <b-table
          responsive
          striped
          hover
          show-empty
          class="position-relative"
          :items="reviewData.actions"
          :fields="actionFields"
          :per-page="actionPerPage"
          :current-page="actionCurrentPage"
          :sort-by.sync="actionSortBy"
          :sort-desc.sync="actionSortDesc"
          :sort-direction="actionSortDirection"
          :filter="actionFilter"
          :filter-included-fields="actionFilterOn"
          @filtered="onActionFiltered"
        >
          <!--  Have the name display N/A if there is no name saved    -->
          <template #cell(status)="data">
            <b-badge
              v-if="data.value === 'OPEN'"
              pill
              variant="success"
              class="text-capitalize"
            >
              {{ data.value }}
            </b-badge>
            <b-badge
              v-if="data.value === 'CLOSED'"
              pill
              variant="danger"
              class="text-capitalize"
            >
              {{ data.value }}
            </b-badge>
          </template>
        </b-table>
        <!-- Pagination -->
        <div class="float-right mb-1">
          <b-pagination
            v-model="actionCurrentPage"
            :total-rows="actionFilter !== null ? actionFilteredRows : actionTotalRows"
            :per-page="actionPerPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
        <!-- ./Pagination -->
      </div>
    </b-card-actions>
    <!-- /Actions Section     -->
    <action-review-modal
      :action="action" :review-object="reviewData" @refresh="refresh"
    />
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { BPagination, BTable, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
// eslint-disable-next-line import/extensions
import specificationHelpers from '@/views/Specification/specificationHelpers.js'
import CommentCard from '@/components/Comments/CommentCard.vue'
import ActionReviewModal from '@/views/Specification/modals/ActionReviewModal.vue'

export default {
  name: 'ReviewCard',
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BTable,
    BPagination,
    BCardActions,
    CommentCard,
    ActionReviewModal,
  },
  props: {
    reviewType: {
      type: String,
      required: true,
      validator: value => ['Review', 'ApprovalRequest'].indexOf(value) !== -1,
    },
    reviewData: {
      type: Object,
      required: true,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const statusTitle = computed(() => {
      // const { reviewType } = props
      const { status } = props.reviewData

      let state = ''
      if (status === 'OPEN') { state = 'in progress' }
      if (status === 'CLOSED') { state = 'closed' }
      if (status === 'FINALISED') { state = 'finalised' }

      // return `${reviewType} ${state}`
      return `Review ${state}`
    })
    const { resolveReviewStatusVariant } = specificationHelpers()

    const showReviewerTable = ref(true)
    const showActionTable = ref(false)

    return {
      statusTitle,
      resolveReviewStatusVariant,
      showReviewerTable,
      showActionTable,
    }
  },
  data() {
    return {
      loading: false,
      newReviewer: '',
      fields: [
        {
          key: 'decision',
          label: 'Decision',
          sortable: true,
          thStyle: { width: '10rem' },
        },
        {
          key: 'reviewer',
          label: 'Reviewer',
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          formatter: this.getUserUsername,
        },
        { key: 'actions', label: '', thStyle: { width: '5rem' } },
      ],
      perPage: 5,
      pageOptions: [3, 5, 10],
      filteredRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      action: '',
      actionFields: [
        {
          key: 'status',
          label: 'Status',
          sortable: true,
          thStyle: { width: '10rem' },
        },
        {
          key: 'action_reason',
          label: 'Reason',
          sortable: true,
          thStyle: { width: '45rem' },
          tdClass: 'text-center',
        },
        {
          key: 'created_by',
          label: 'Created By',
          sortable: true,
          thStyle: { width: '5rem' },
          filterByFormatted: true,
          sortByFormatted: true,
          formatter: this.getUserUsername,
        },
        {
          key: 'created',
          label: 'Date Actioned',
          sortable: true,
          thStyle: { width: '5rem' },
        },
      ],
      actionPerPage: 5,
      actionPageOptions: [3, 5, 10],
      actionFilteredRows: 1,
      actionCurrentPage: 1,
      actionSortBy: '',
      actionSortDesc: false,
      actionSortDirection: 'asc',
      actionFilter: null,
      actionFilterOn: [],

    }
  },
  computed: {
    userReviewStatus() {
      const userReview = this.reviewData.reviewers.find(e => e.reviewer === this.$store.state.auth.id)
      if (userReview) {
        return userReview.decision
      }
      return null
    },
    isUserOwner() {
      return this.reviewData.created_by === this.$store.state.auth.id
    },
    isUserReviewer() {
      console.log(this.reviewData.reviewers) // Log the whole reviewers array
      console.log(this.$store.state.auth.id) // Log the auth id
      // Log the entire reviewers array to see its structure
      console.log('Reviewers Array:', JSON.stringify(this.reviewData.reviewers, null, 2))

      // Log the auth id from the store
      console.log('Auth ID:', this.$store.state.auth.id)

      // Check the type of the auth id to ensure it's a string
      console.log('Auth ID Type:', typeof this.$store.state.auth.id)
      console.log('Auth ID Type:', typeof this.$store.state.auth.id)
      console.log(this.reviewData.reviewers.find(e => {
        console.log(`Comparing ${typeof e.reviewer} with ${typeof this.$store.state.auth.id}`)
        return e.reviewer === String(this.$store.state.auth.id)
      }))

      return this.reviewData.reviewers.find(e => e.reviewer === this.$store.state.auth.id)
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    totalRows() {
      return this.reviewData.reviewers.length
    },
    actionSortOptions() {
      // Create an options list from our fields
      return this.actionFields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    actionTotalRows() {
      return this.reviewData.actions.length
    },
  },
  methods: {
    refresh() {
      this.$refs.reviewersCard.showLoading = true
      this.$store
        .dispatch('SpecificationPage/fetchSpecification', this.$route.params.specId)
        .then(() => {
          this.$refs.reviewersCard.showLoading = false
        })
    },
    actionReview(action) {
      this.action = action
      this.reviewObject = this.reviewData
      this.$bvModal.show('action-review-modal')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filteredRows = filteredItems.length
      this.currentPage = 1
    },
    onActionFiltered(actionFilteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.actionFilteredRows = actionFilteredItems.length
      this.actionCurrentPage = 1
    },
    deleteReviewer(reviewerToDelete) {
      reviewerToDelete = this.getUserUsername(reviewerToDelete)
      this.$bvModal.msgBoxConfirm(
        `Remove ${reviewerToDelete} from Review?`,
        {
          title: 'Remove Reviewer',
          size: 'md',
          okVariant: 'danger',
          okTitle: 'Yes, remove reviewer',
          cancelVariant: 'outline-secondary',
          cancelTitle: 'No, keep reviewer',
          hideHeaderClose: false,
          centered: true,
        },
      )
        .then(actionConfirmation => {
          if (actionConfirmation === true) {
            this.$emit('update-review', {
              action: 'remove',
              username: reviewerToDelete,
            })
          }
        })
    },
    addReviewer() {
      // Validate and add the new user
      this.$coreService.usersManagementApi.checkUsernameExists(this.newReviewer)
        .then(response => {
          console.log(response)
          if (response) {
            this.$emit('update-review', {
              action: 'add',
              username: this.newReviewer,
            })
            this.newReviewer = ''
            this.$refs.username.focus()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error adding user to review',
                icon: 'CheckIcon',
                variant: 'danger',
                text: 'Failed to share add user to the review (User does not exist)',
              },
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
.hide {
  display: none;
}

td[aria-colindex="1"]:hover .hide{
  display: inline-block;
}
</style>
