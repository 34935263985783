<template>
  <b-card-actions
    ref="baselinesCard"
    title="Baselines"
    no-body
    action-collapse
    :collapsed="baselinesData.length === 0"
    :show-loading="loading"
    @refresh="refresh"
  >
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">

        <!-- Sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="1"
          label-for="sortBySelect"
          label-class="mt-50 mr-50 pl-0"
          class="mb-0 text-nowrap"
          style="min-width: 20rem"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
        <!-- ./Sorting  -->

        <!-- Search -->
        <b-form-group
          label="Search"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          label-class="mt-50"
          class="mb-0"
          style="width: 30rem"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!-- ./Search -->
      </div>
    </b-card-body>

    <b-table
      responsive
      striped
      hover
      show-empty
      class="position-relative"
      :fields="fields"
      :items="baselinesData"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(version)="data">
        <span id="baseline-name-field" class="text-nowrap font-weight-bold">{{ data.value }}</span>
      </template>

      <template #cell(created)="data">
        <span class="text-nowrap">
          {{ data.value | shortDate }}
          <span class="text-muted font-small-2">{{ data.value | diffForHumans }}</span>
        </span>
      </template>

      <template #cell(actions)="data">
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="flat-primary"
            @click="compareBaseline(data)"
          >
            Compare
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="flat-success"
            @click="exportBaselineToDoors(data.item)"
          >
            Export to DOORs
          </b-button>
        </b-button-group>
      </template>

      <template #cell(last_pushed_to_doors)="data">
        <span class="text-nowrap">
          <span v-if="data.value" class="text-muted font-small-2">{{ data.value | diffForHumans }}</span>
          <span v-else class="text-muted font-small-2">Not Exported</span>
        </span>
      </template>

      <template #cell(baseline_last_stored)="data">
        <span class="text-nowrap">
          <span v-if="data.value" class="text-muted font-small-2">{{ data.value | diffForHumans }}</span>
          <span v-else class="text-muted font-small-2">Not Stored</span>
        </span>
      </template>
    </b-table>

    <b-card-body
      v-if="totalRows > 10"
      class="d-flex justify-content-between flex-wrap pt-0"
    >
      <!-- Page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-class="mt-50"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>
      <!-- ./Page length -->

      <!-- Pagination -->
      <div>
        <b-pagination
          v-if="totalRows > perPage"
          v-model="currentPage"
          :total-rows="filter !== null ? filteredRows : totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
      <!-- ./Pagination -->
    </b-card-body>

    <ExportBaselineToDoorsModal :baseline-target="targetBaseline" />
  </b-card-actions>
</template>

<script>
import { BPagination, BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ExportBaselineToDoorsModal from '@/views/Specification/modals/ExportBaselineToDoorsModal.vue'

export default {
  name: 'BaselinesCard',
  directives: {
    Ripple,
  },
  components: {
    ExportBaselineToDoorsModal,
    BCardActions,
    BTable,
    BPagination,
  },
  props: {
    baselinesData: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    fields: [
      {
        key: 'version',
        label: 'Version',
        sortable: true,
        thStyle: { width: '2%' },
        tdStyle: { width: '2%' },
      },
      { key: 'created', label: 'Date', sortable: true },
      { key: 'actions', label: 'Open', sortable: false },
      { key: 'last_pushed_to_doors', label: 'Exported', sortable: true },
      { key: 'baseline_last_stored', label: 'Stored', sortable: true },
    ],
    perPage: 10,
    pageOptions: [3, 5, 10, 25, 50, 100],
    filteredRows: 1,
    currentPage: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    filterOn: [],
    targetBaseline: {},
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    totalRows() {
      return this.baselinesData.length
    },
  },
  methods: {
    refresh() {
      this.$refs.baselinesCard.showLoading = true
      this.$store
        .dispatch('SpecificationPage/fetchSpecification', this.$route.params.id)
        .finally(() => {
          this.$refs.baselinesCard.showLoading = false
        })
    },
    compareBaseline(baseline) {
      // console.log(baseline)
      this.$router.push({
        name: 'specification_baseline_compare',
        params: {
          modelId: sessionStorage.getItem('kompozition-workspace'),
          specId: baseline.item.specification_id,
          ver: baseline.item.version,
        },
      })
    },
    exportBaselineToDoors(baseline) {
      this.targetBaseline = baseline
      this.$bvModal.show('export-baseline-to-ibm-doors-next')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filteredRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
