<template>
  <div>
    <b-sidebar
      id="sidebar-1"
      title="Custom Properties"
      bg-variant="white"
      shadow="true"
      width="30vw"
      right
      backdrop
    />

    <b-card-actions
      ref="customPropertyCard"
      title="Custom Properties"
      no-body
      action-refresh
      action-collapse
      :collapsed="customPropertiesData.length === 0"
      :show-loading="loading"
      @refresh="refresh"
    >
      <template #title>
        <b-button
          class="ml-1"
          variant="outline-success"
          size="sm"
          @click="addCustomProperties"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </template>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">

          <!-- Sorting  -->
          <b-form-group
            label="Sort"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="1"
            label-for="sortBySelect"
            label-class="mt-50 mr-50 pl-0"
            class="mb-0 text-nowrap"
            style="min-width: 20rem"
          >
            <b-input-group
              size="sm"
            >
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
                <template #first>
                  <option value="">
                    none
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
          <!-- ./Sorting  -->

          <!-- Search -->
          <b-form-group
            label="Search"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            label-class="mt-50"
            class="mb-0"
            style="width: 30rem"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <!-- ./Search -->
        </div>
      </b-card-body>

      <b-table
        responsive
        striped
        hover
        show-empty
        class="position-relative"
        :fields="fields"
        :items="customPropertiesData"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(label)="data">
          <span class="font-weight-bold">{{ data.item.label }}</span>
        </template>
        <template #cell(actions)="row">
          <b-button-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="flat-primary"
              @click="showUpdateModal(row.item)"
            >
              <feather-icon
                icon="EditIcon"
                size="14"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="flat"
              @click="showDeleteModal(row.item)"
            >
              <feather-icon
                icon="TrashIcon"
                size="14"
              />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- Page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-class="mt-50"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
        <!-- ./Page length -->

        <!-- Pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="filter !== null ? filteredRows : totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
        <!-- ./Pagination -->
      </b-card-body>
      <!-- Modals -->
      <custom-properties-create />
      <custom-properties-update
        :custom-property-label="customPropertyLabel"
        :custom-property-value="customPropertyValue"
      />
      <custom-properties-delete
        :custom-property-label="customPropertyLabel"
        :custom-property-value="customPropertyValue"
      />
    </b-card-actions>
  </div>
</template>

<script>
import { BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import CustomPropertiesCreate from '@/views/Specification/modals/CustomPropertiesCreate.vue'
import CustomPropertiesUpdate from '@/views/Specification/modals/CustomPropertiesUpdate.vue'
import CustomPropertiesDelete from '@/views/Specification/modals/CustomPropertiesDelete.vue'
import { nextTick } from '@vue/composition-api'

export default {
  name: 'CustomPropertiesCard',
  directives: {
    Ripple,
  },
  components: {
    BCardActions,
    BTable,
    BPagination,
    CustomPropertiesCreate,
    CustomPropertiesUpdate,
    CustomPropertiesDelete,
  },
  props: {
    customPropertiesData: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    fields: [
      { key: 'label', label: 'Label', sortable: true },
      { key: 'defaultValue', label: 'Default Value', sortable: true },
      {
        key: 'actions', label: 'Actions', thClass: 'text-right', tdClass: 'text-right',
      },
    ],
    perPage: 5,
    pageOptions: [3, 5, 10, 25, 50, 100],
    filteredRows: 1,
    currentPage: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    filterOn: [],
    customPropertyLabel: '',
    customPropertyValue: '',
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    totalRows() {
      return this.customPropertiesData.length
    },
  },
  methods: {
    refresh() {
      this.$refs.customPropertyCard.showLoading = true
      this.$store
        .dispatch('SpecificationPage/fetchSpecification', this.$route.params.specId)
        .then(() => {
          this.$refs.customPropertyCard.showLoading = false
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filteredRows = filteredItems.length
      this.currentPage = 1
    },
    addCustomProperties() {
      this.$bvModal.show('create-custom-properties-modal')
    },
    async showUpdateModal(item) {
      this.customPropertyLabel = item.label
      this.customPropertyValue = item.defaultValue
      await nextTick()
      this.$bvModal.show('modal__UpdateCustomProperties')
    },
    async showDeleteModal(item) {
      this.customPropertyLabel = item.label
      this.customPropertyValue = item.defaultValue
      await nextTick()
      this.$bvModal.show('delete-custom-properties-modal')
    },
  },
}
</script>
