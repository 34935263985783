<template>
  <b-modal
    id="reject-spec-approval"
    title="Reject Approval"
    ok-variant="warning"
    ok-title="Reject Approval"
    cancel-variant="outline-secondary"
    cancel-title="Dismiss"
    size="lg"
    no-close-on-esc
    no-close-on-backdrop
    centered
    @ok="rejectApproval"
  >
    <p class="text-center">
      Please provide a reason why this approval is getting rejected.
    </p>
    <b-form-group label="Enter reason here:" label-for="reject-approval-reason">
      <b-form-input
        id="reject-approval-reason"
        v-model="reason"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
export default {
  name: 'RejectApproval',
  data() {
    return {
      reason: '',
    }
  },
  methods: {
    rejectApproval() {
      console.log('Reject Modal')
      this.$emit('rejected', this.reason)
    },
  },
}
</script>

<style scoped>

</style>
