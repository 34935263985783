import coreService from '@/libs/api-services/core-service'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import router from '@/router'

const getDefaultState = () => ({
  approval: false,
  baselines: [],
  issues: [],
  customProperties: [],
  requirements: [],
  review: {},
  snapshots: [],
  specification: {},
  baselineStatus: '',
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    SET_SPECIFICATION: (state, data) => {
      state.approval = data.approval
      state.baselines = data.baselines
      state.issues = data.issues
      state.customProperties = data.customProperties
      state.requirements = data.requirements
      state.review = data.review
      state.snapshots = data.snapshots
      state.specification = data.specification
    },
    SET_REVIEW: (state, data) => {
      state.review = { ...state.obj, ...data }
    },
    CLEAR: state => { Object.assign(state, getDefaultState()) },
    SET_BASELINE_STATUS: (state, data) => { state.baselineStatus = data },
  },
  actions: {
    fetchSpecification({ state, commit }, specificationId) {
      const { modelId } = router.currentRoute.params
      const params = { model: modelId }
      return axiosIns
        .get(`/api/v2/specifications/${specificationId}`, { params })
        .then(({ data }) => {
          commit('SET_SPECIFICATION', data)
          commit('app/SET_DYNAMIC_PAGE_TITLE', data.specification.title, { root: true })
        })
        .catch(e => console.error(e))
    },
    // Used to refresh UI content if there are conflicts due to potentially multiple users
    refreshSpecification({
      state, dispatch,
    }) {
      // Short delay so the user can actually read the toasts
      // - Fetching Toast
      setTimeout(() => Vue.$toast({
        component: ToastificationContent,
        props: {
          title: 'Fetching new content',
          text: 'Refreshing page data due to error, please wait...',
          icon: 'LoaderIcon',
          variant: 'warning',
        },
      }), 1000)
      // -- Data refresh Toast
      setTimeout(() => dispatch('fetchSpecification', state.specification.id)
        .then(() => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Fetched new content',
              text: 'Page data refreshed',
              icon: 'TickIcon',
              variant: 'success',
            },
          })
        }), 1500)
    },
    startReview({ state, commit, dispatch }, specificationId) {
      return axiosIns
        .post(`/api/v2/specifications/${specificationId}/approval/start`)
        .then(({ data }) => {
          commit('SET_REVIEW', data)
          dispatch('fetchSpecification', state.specification.id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Specification review started',
              text: `${state.specification.title}`,
              icon: 'PlayCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: 'Failed to start review',
              icon: 'TickIcon',
              variant: 'danger',
            },
          })
          dispatch('refreshSpecification')
        })
    },
    closeReview({ state, dispatch }, specificationId) {
      return axiosIns
        .put(`/api/v2/specifications/${specificationId}/review/close`)
        .then(() => {
          dispatch('fetchSpecification', state.specification.id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Closed Specification review',
              text: `${state.specification.title}`,
              icon: 'CheckCircleIcon',
              variant: 'warning',
            },
          })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: 'Failed to close review',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    openReview({ state, dispatch }, specificationId) {
      return axiosIns
        .put(`/api/v2/specifications/${specificationId}/review/open`)
        .then(() => {
          dispatch('fetchSpecification', state.specification.id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Opened Specification review',
              text: `${state.specification.title}`,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: 'Failed to open review',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    finaliseReview({ state, dispatch }, specificationId) {
      return axiosIns
        .put(`/api/v2/specifications/${specificationId}/review/finalise`)
        .then(() => {
          dispatch('fetchSpecification', state.specification.id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Finalised Specification review',
              text: `${state.specification.title}`,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: 'Failed to finalise review',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          dispatch('refreshSpecification')
        })
    },
    updateReview({ state, dispatch }, payload) {
      return axiosIns
        .patch(`/api/v2/specifications/${state.specification.id}/review/reviewers`, payload)
        .then(() => {
          dispatch('fetchSpecification', state.specification.id)
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update Review',
              text: `${e}`,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
          dispatch('refreshSpecification')
        })
    },
    updateApproval({ state, dispatch }, payload) {
      return axiosIns
        .patch(`/api/v2/specifications/${state.specification.id}/approval/reviewers`, payload)
        .then(() => {
          dispatch('fetchSpecification', state.specification.id)
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to update Review',
              text: `${e}`,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
          dispatch('refreshSpecification')
        })
    },
    rejectApproval({ state, dispatch }, payload) {
      return axiosIns
        .put(`/api/v2/specifications/${state.specification.id}/approval/action`, payload)
        .then(() => {
          dispatch('fetchSpecification', state.specification.id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Rejected Approval',
              text: `${state.specification.title}`,
              icon: 'CheckCircleIcon',
              variant: 'warning',
            },
          })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: 'Failed to reject approval',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    createBaseline({ state, dispatch, commit }, payload) {
      return axiosIns
        .put(`/api/v2/specifications/${state.specification.id}/approval/approve`, payload)
        .then(() => {
          dispatch('fetchSpecification', state.specification.id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Specification Baselined',
              text: `${state.specification.title} baselined to version ${payload.version}`,
              icon: 'TickIcon',
              variant: 'success',
            },
          })
          commit('SET_BASELINE_STATUS', 'PASS')
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Operation unsuccessful',
              text: `${e}`,
              icon: 'CrossIcon',
              variant: 'danger',
            },
          })
          commit('SET_BASELINE_STATUS', 'FAIL')
          dispatch('refreshSpecification')
        })
    },
    setAsDefaultSpecification({ state, dispatch }, specificationId) {
      const { modelId } = router.currentRoute.params
      const payload = { model: modelId }
      return axiosIns
        .patch(`/api/v2/specifications/${specificationId}/set_default`, { model: payload.model })
        .then(() => {
          dispatch('fetchSpecification', state.specification.id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Changed default Specification',
              text: `${state.specification.title} has been made the default specification`,
              icon: 'TickIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Operation unsuccessful',
              text: `${e}`,
              icon: 'CrossIcon',
              variant: 'danger',
            },
          })
          dispatch('refreshSpecification')
        })
    },
    createSnapshot({ state, dispatch }, payload) {
      return coreService
        .post(`/v1/specifications/${state.specification.id}/snapshot/create`, payload)
        .then(() => {
          dispatch('fetchSpecification', state.specification.id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Specification Snapshot Created',
              text: `Snapshot for ${state.specification.title} created`,
              icon: 'TickIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Operation unsuccessful',
              text: `${e}`,
              icon: 'CrossIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteSnapshot({ state, dispatch }, payload) {
      return coreService
        .delete(`/v1/specifications/${payload.specId}/snapshot/${payload.snapId}`)
        .then(() => {
          dispatch('fetchSpecification', state.specification.id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Specification Snapshot Deleted',
              text: `Snapshot for ${state.specification.title} deleted`,
              icon: 'TickIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Operation unsuccessful',
              text: `${e}`,
              icon: 'CrossIcon',
              variant: 'danger',
            },
          })
          dispatch('refreshSpecification')
        })
    },
    createCustomProperty({ state, dispatch }, payload) {
      const { modelId } = router.currentRoute.params
      const headers = { 'model-id': modelId }
      return coreService
        .post(`/v1/specifications/${state.specification.id}/custom_properties`, payload, { headers })
        .then(() => {
          dispatch('fetchSpecification', state.specification.id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Custom Properties Created',
              text: 'Custom Properties has been successfully created',
              icon: 'TickIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Operation unsuccessful',
              text: `${e.response.data.detail}`,
              icon: 'CrossIcon',
              variant: 'danger',
            },
          })
          dispatch('refreshSpecification')
        })
    },
    updateCustomProperty({ state, dispatch }, payload) {
      const { modelId } = router.currentRoute.params
      const headers = { 'model-id': modelId }
      return coreService
        .put(`/v1/specifications/${state.specification.id}/custom_properties`, payload, { headers })
        .then(() => {
          dispatch('fetchSpecification', state.specification.id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Custom Properties Updated',
              text: 'Custom Properties has been successfully updated',
              icon: 'TickIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Operation unsuccessful',
              text: `${e.response.data.detail}`,
              icon: 'CrossIcon',
              variant: 'danger',
            },
          })
          dispatch('refreshSpecification')
        })
    },
    deleteCustomProperty({ state, dispatch }, payload) {
      const { modelId } = router.currentRoute.params
      const config = {
        headers: { 'model-id': modelId },
        data: payload,
      }
      return coreService
        .delete(`/v1/specifications/${state.specification.id}/custom_properties`, config)
        .then(() => {
          dispatch('fetchSpecification', state.specification.id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Custom Property Deleted',
              text: 'Custom Property has been successfully deleted',
              icon: 'TickIcon',
              variant: 'success',
            },
          })
        })
        .catch(e => {
          console.error(e)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Operation unsuccessful',
              text: `${e.response.data.detail}`,
              icon: 'CrossIcon',
              variant: 'danger',
            },
          })
          dispatch('refreshSpecification')
        })
    },
  },
}
