<template>
  <div>
    <b-button
      size="sm"
      variant="outline-primary"
      class="ml-1"
      @click="openDedicated"
    >
      <feather-icon
        icon="LinkIcon"
        size="12"
      />
      Open Dedicated
    </b-button>
    <b-button
      size="sm"
      variant="outline-primary"
      class="ml-1"
      @click="openTable"
    >
      <feather-icon
        icon="ListIcon"
        size="12"
      />
      Show in Table
    </b-button>
    <requirement-details :is-sidebar="true" />

    <!-- Modals -->
    <add-requirement-modal :relation-type="requirement_relationship" parent="" parent_rel="sibling" />
    <copy-requirement-modal />
    <move-requirement-modal />
    <edit-requirement-modal />
    <delete-requirement-modal />
    <impact-requirement-modal
      :requirement-id="requirement.properties.id"
      :properties="requirement.properties"
    />
    <history-requirement-modal />
    <trace-forward-modal :selected-requirements="[requirement.properties.id]" />
    <trace-backward-modal :selected-requirements="[requirement.properties.id]" />
    <parse-requirement-modal />
    <create-note-modal :parent-ids="[requirement.properties.id]" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RequirementDetails from '@/views/RequirementsTableLegacy/components/DetailsSidebar.vue'
import RequirementsModalAdd from '@/views/RequirementsTableLegacy/modals/Add.vue'
import RequirementsModalCopy from '@/views/RequirementsTableLegacy/modals/Copy.vue'
import RequirementsModalMove from '@/views/RequirementsTableLegacy/modals/Move.vue'
import RequirementsModalEdit from '@/views/RequirementsTableLegacy/modals/Edit.vue'
import RequirementsModalDelete from '@/views/RequirementsTableLegacy/modals/Delete.vue'
import RequirementsModalImpact from '@/views/RequirementsTableLegacy/modals/ChangeImpactAnalysis.vue'
import RequirementsModalHistory from '@/views/RequirementsTableLegacy/modals/History.vue'
import RequirementsModalParse from '@/views/RequirementsTableLegacy/modals/Parse.vue'
import CreateNoteModal from '@/components/Notes/Modals/CreateNoteModal.vue'
import TraceBackwardModal from '@/views/RequirementsTableLegacy/modals/TraceBackwardModal.vue'
import TraceForwardModal from '@/views/RequirementsTableLegacy/modals/TraceForwardModal.vue'

export default {
  name: 'RequirementSidebar',
  components: {
    RequirementDetails,
    AddRequirementModal: RequirementsModalAdd,
    CopyRequirementModal: RequirementsModalCopy,
    MoveRequirementModal: RequirementsModalMove,
    EditRequirementModal: RequirementsModalEdit,
    DeleteRequirementModal: RequirementsModalDelete,
    ImpactRequirementModal: RequirementsModalImpact,
    HistoryRequirementModal: RequirementsModalHistory,
    ParseRequirementModal: RequirementsModalParse,
    TraceForwardModal,
    TraceBackwardModal,
    CreateNoteModal,
  },
  data() {
    return {
      requirement_relationship: null,
    }
  },
  computed: {
    ...mapState({
      requirement: state => state.requirements.selected_requirement,
      spec: state => state.specifications.selectedSpecification.specification,
    }),
  },
  mounted() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  beforeDestroy() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
  },
  methods: {
    openTable() {
      this.$store.dispatch('requirementsTableLegacy/setSpecification', this.spec.id)
        .then(() => {
          this.$router.push(
            {
              name: 'legacy_requirements_table',
              params: { specId: this.spec.id },
              query: {
                focus: this.requirement.properties.id,
              },
            },
          )
        })
    },
    openDedicated() {
      this.$router.push(
        {
          name: 'requirement_detail',
          params: {
            requirementId: this.requirement.properties.id,
          },
        },
      )
    },
  },
}
</script>
