<template>
  <b-modal
    id="rollback-snapshot-modal"
    title="Rollback Snapshot"
    centered
    @ok="rollbackSnapshot"
    @shown="init"
  >
    <p>Are you sure you want to rollback the specification to snapshot <span class="font-weight-bolder">{{ snapshotObject.description }}</span>?</p>
    <p>This will undo all requirement changes made after the snapshot.</p>

    <p>Please enter <span class="font-weight-bolder">CONFIRM</span> below to rollback.</p>
    <span class="font-weight-lighter text-secondary font-small-1">Input is case sensitive.</span>
    <b-input id="snapshot-rollback-confirmation-field" v-model="typedName" />
    <template #modal-footer="{ ok, cancel }">
      <b-button :disabled="isBtnDisabled" size="sm" :variant="isBtnDisabled ? 'outline-danger' : 'danger'" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Rolling Back...
        </span>
        <span v-else>
          Roll Back
        </span>
      </b-button>
      <b-button size="sm" variant="secondary" @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import coreService from '@/libs/api-services/core-service'

export default {
  name: 'DeleteSnapshot',
  props: {
    snapshotObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBtnDisabled: true,
      typedName: '',
      loading_status: false,
    }
  },
  watch: {
    typedName(val) {
      this.isBtnDisabled = val !== 'CONFIRM'
    },
  },
  methods: {
    init() {
      this.typedName = ''
      this.loading_status = false
    },
    rollbackSnapshot() {
      this.loading_status = true
      const payload = {
        model_id: this.$store.state.model.id,
      }
      coreService
        .post(
          `/v1/specifications/${this.snapshotObject.specification_id}/snapshot/${this.snapshotObject.id}/rollback`,
          payload,
        ).then(({ data }) => {
          this.loading_status = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Specification rolled back',
              text: `Specification rolled back to ${this.snapshotObject.description}`,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('rollback-snapshot-modal')
          this.$emit('rolled')
        })
    },
  },
}
</script>

<style scoped>

</style>
