<template>
  <b-card-actions
    ref="snapshotsCard"
    title="Snapshots"
    no-body
    action-refresh
    action-collapse
    :show-loading="loading"
    :collapsed="snapshotsData.length === 0"
    @refresh="refresh"
  >
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <b-form-group
          label="Description"
          label-for="txtSnapshotNotes"
          class="w-100"
        >
          <div class="d-inline-flex w-100 justify-content-start">
            <div class="w-75">
              <b-form-input
                id="txtSnapshotNotes"
                v-model="snapshotNotes"
                placeholder="Enter a snapshot description..."
                class=""
                size="sm"
              />
            </div>
            <div>
              <b-button
                v-b-tooltip.hover.right="'Create a snapshot of the Specification'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-success"
                class="ml-50 w-100 text-nowrap"
                size="sm"
                @click="createSnapshot"
              >
                Create Snapshot
              </b-button>
            </div>
          </div>
        </b-form-group>
      </div>

      <div class="d-inline-flex w-100 justify-content-between">
          <div class="w-25">
            <!-- Sorting -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-for="sortBySelect"
              label-class="mt-50 mr-50 pl-0"
              class="mb-0 text-nowrap"
              style="min-width: 20rem"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
            <!-- ./Sorting  -->
          </div>
          <div class="w-50">
            <!-- Search -->
            <b-form-group
              label="Search"
              label-size="sm"
              label-for="filterInput"
              label-class="mt-50"
              class="mb-0 ml-2"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- ./Search -->
          </div>
      </div>
    </b-card-body>

    <b-table
      responsive
      striped
      hover
      show-empty
      class="position-relative"
      :fields="fields"
      :items="snapshotsData"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(snapshot_desc)="data">
        <span class="text-nowrap font-weight-bold">{{ data.item.description }}</span>
      </template>

      <template #cell(snapshot_date)="data">
        <span>
          {{ data.item.created | shortDate }}
          <span class="text-muted font-small-2">{{ data.item.created | diffForHumans }}</span>
        </span>
      </template>

      <template #cell(actions)="data">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="flat-primary"
          @click="compareSnapshot(data)"
        >
          Compare
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="flat-danger"
          @click="deleteSnapshot(data)"
        >
          <feather-icon
            icon="TrashIcon"
            size="14"
          />
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="flat-danger"
          title="Rollback to this snapshot"
          @click="rollbackToSnapshot(data)"
        >
          <feather-icon
            icon="SkipBackIcon"
            size="14"
          />
          Rollback
        </b-button>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- Page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-class="mt-50"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>
      <!-- ./Page length -->

      <!-- Pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
      <!-- ./Pagination -->
    </b-card-body>

    <!-- Modals -->
    <snapshot-delete
      :snapshot-object="snapshotObject"
    />
    <snapshot-rollback
      :snapshot-object="snapshotObject" @rolled="refresh"
    />
  </b-card-actions>
</template>

<script>
import { BPagination, BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SnapshotDelete from '@/views/Specification/modals/SnapshotDelete.vue'
import SnapshotRollback from '@/views/Specification/modals/SnapshotRollback.vue'

export default {
  name: 'SnapshotsCard',
  directives: {
    Ripple,
  },
  components: {
    BCardActions,
    BTable,
    BPagination,
    SnapshotDelete,
    SnapshotRollback,
  },
  props: {
    snapshotsData: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    fields: [
      { key: 'snapshot_desc', label: 'Description', sortable: true },
      // { key: 'snapshot_desc', label: 'Description', sortable: true },
      { key: 'snapshot_date', label: 'Snapshot Date', sortable: true },
      { key: 'actions', label: 'Actions', sortable: false },
    ],
    perPage: 5,
    pageOptions: [3, 5, 10, 25, 50, 100],
    filteredRows: 1,
    currentPage: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    filterOn: [],
    // Modal
    snapshotObject: {},
    snapshotNotes: '',
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    totalRows() {
      return this.snapshotsData.length
    },
  },
  methods: {
    refresh() {
      this.$refs.snapshotsCard.showLoading = true
      this.$store
        .dispatch('SpecificationPage/fetchSpecification', this.$route.params.specId)
        .then(() => {
          this.$refs.snapshotsCard.showLoading = false
        })
    },
    createSnapshot() {
      this.$emit('create', this.snapshotNotes)
      this.snapshotNotes = ''
    },
    compareSnapshot(snapshotId) {
      // console.log(`Comparing snapshot (${snapshotId}) with current specification`)
      // console.log(snapshotId)
      this.$router.push({
        name: 'specification_snapshot_compare',
        params: {
          modelId: sessionStorage.getItem('kompozition-workspace'),
          specId: snapshotId.item.specification_id,
          snapId: snapshotId.item.id,
        },
      })
    },
    rollbackToSnapshot(snapshot) {
      console.log('Rollback to snapshot ', snapshot.item)
      this.snapshotObject = snapshot.item
      this.$bvModal.show('rollback-snapshot-modal')
    },
    deleteSnapshot(snapshot) {
      console.log('Delete snapshot ', snapshot.item)
      this.snapshotObject = snapshot.item
      this.$bvModal.show('delete-snapshot-modal')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
