<template>
  <b-modal
    id="create-custom-properties-modal"
    title="Create Custom Properties"
    size="lg"
    centered
    no-close-on-backdrop
    @show="onShow"
  >
    <b-col v-if="isLoading" class="d-flex justify-content-start ml-1">
      <b-spinner
        small
        class="align-self-center mr-1"
      />
      Please wait...
    </b-col>
    <div v-if="!isLoading">
      <div class="scroll-container-60">
        <b-row v-for="(property, k) in newCustomProperties" :key="k" class="row-border">
          <b-col>
            <label>Property Label</label>
            <input v-model="property.name" class="form-control" type="text" required>
          </b-col>
          <b-col>
            <label>Property Default Value</label>
            <input v-model="property.defaultValue" class="form-control" type="text">
          </b-col>
          <b-col style="padding-top: 30px">
            <b-form-checkbox v-model="property.add_to_existing_requirements" v-b-tooltip.hover.right="'This will update all the Requirements within this Specification with this new Label and Default Value'" value="true">
              Update Existing Requirements
            </b-form-checkbox>
          </b-col>
          <b-col style="padding-top: 30px">
            <b-button variant="outline-secondary" @click="removeRow(k)">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <br>
      <b-button @click="addNewRow()">
        Add
      </b-button>

    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button :disabled="isLoading" size="sm" :variant="isLoading ? 'outline-primary' : 'primary'" @click="createCustomProperties()">
        Create
      </b-button>
      <b-button size="sm" variant="secondary" @click="cancel()">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateCustomProperties',
  data() {
    return {
      newCustomProperties: [{ name: '', defaultValue: '', add_to_existing_requirements: false }],
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      protectedReqAttributes: 'constants/protectedReqAttributes',
    }),
  },
  methods: {
    onShow() {
      this.newCustomProperties = [{ name: '', defaultValue: '', add_to_existing_requirements: false }]
      this.protected_attributes = this.protectedReqAttributes.map(attr => attr.value)
    },
    addNewRow() {
      this.newCustomProperties.push({
        name: '',
        defaultValue: '',
        add_to_existing_requirements: false,
      })
    },
    removeRow(k) {
      this.newCustomProperties.splice(k)
    },
    findInvalidProperties() {
      let invalid = false
      this.newCustomProperties.forEach((prop, i) => {
        let text = ''
        if (prop.name === '') {
          text = 'Property labels cannot be blank'
        } else if (this.protected_attributes.includes(prop.name)) {
          text = `Property label: ${prop.name} is protected`
        } else return
        invalid = true
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invalid Property Label',
            text,
            icon: 'AlertIcon',
            variant: 'danger',
          },
        })
      })
      return invalid
    },
    createCustomProperties() {
      if (this.findInvalidProperties()) return
      this.isLoading = true
      const customPropertyObject = { properties: this.newCustomProperties }
      this.$store.dispatch('SpecificationPage/createCustomProperty', customPropertyObject).then(() => {
        this.$bvModal.hide('create-custom-properties-modal')
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>
 .row-border {
     border-bottom: 1px solid #44444444;
     padding-bottom: 1em;
     padding-top: 1em;
 }
 .scroll-container-60 {
     overflow-y: auto;
     overflow-x: clip;
     max-height: 60vh;
 }
</style>
