import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import axios from 'axios'
import updateToken from '@/auth/middleware/updateToken'

const __showToast = (title, text, timeout = 3700) => {
  Vue.$toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      icon: 'AlertCircleIcon',
      variant: 'danger',
    },
  }, {
    timeout,
  })
}

const doorsIntegrationService = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' },
})

doorsIntegrationService.defaults.baseURL = `${window.location.protocol}//doors-api.${window.location.host}`

// Request Interceptor
doorsIntegrationService.interceptors.request.use(
  async request => {
    const token = await updateToken()
    request.headers.Authorization = `Bearer ${token}`
    return request
  },
  error => {
    console.debug(error)
    return error
  },
)

// Response Interceptor
doorsIntegrationService.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      console.error(error.response)

      let toastTitle = `Error ${error.response.status}`
      let toastMsg = error.response.statusText

      if (error.response.status === 412) {
        toastTitle = `${toastTitle}: ${error.response.statusText}`
        toastMsg = (
          'The artifact has recently been updated by someone else.'
          + '\nPlease verify their changes and try again.'
        )
        __showToast(toastTitle, toastMsg, 8000)
      } else {
        // __showToast(toastTitle, toastMsg)
      }
    } else if (error.request) {
      console.debug(error.request)
      console.debug(error.request.statusText)
      console.debug(error.request.status)

      let toastTitle = `Error ${error.request.status}`
      let toastMsg = error.request.statusText

      if (error.request.status === 0) {
        toastTitle = `${toastTitle}: No response`
        toastMsg = 'Core-Service API is not responding.'
      }
      // __showToast(toastTitle, toastMsg)
    }
    return error
  },
)

Vue.prototype.$doorsIntegrationService = doorsIntegrationService

export default doorsIntegrationService
