<template>
  <b-modal
    id="history-requirement-modal"
    :title="`${requirement.properties.display_id} History`"
    size="lg"
    hide-footer
    @shown="$refs.requirement_history.loadRequirementChangeHistory()"
  >
    <requirement-history ref="requirement_history" />
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import RequirementHistory from '@/views/RequirementsTableLegacy/forms/History.vue'

export default {
  name: 'History',
  components: {
    RequirementHistory,
  },
  computed: {
    ...mapState({
      requirement: state => state.requirements.selected_requirement,
    }),
  },
}
</script>

<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
