export default function specificationHelpers() {
  const resolveReviewStatusVariant = value => {
    if (value === 'OPEN') return 'success'
    if (value === 'CLOSED') return 'danger'
    if (value === 'FINALISED') return 'info'
    return 'secondary'
  }

  return {
    resolveReviewStatusVariant,
  }
}
