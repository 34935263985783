<template>
  <b-modal
    id="delete-custom-properties-modal"
    title="Delete Custom Property"
    centered
    no-close-on-backdrop
  >
    <b-col v-if="isLoading" class="d-flex justify-content-start ml-1">
      <b-spinner
        small
        class="align-self-center mr-1"
      />
      Please wait...
    </b-col>
    <div v-if="!isLoading">
      <p>Are you sure you want to delete the below custom property:<br><br> <span class="font-weight-bolder" style="font-size: 21px"> {{ customPropertyLabel }}</span></p>
      <p>Please enter <span class="font-weight-bolder">CONFIRM</span> below to delete.</p>
      <span class="font-weight-lighter text-secondary font-small-1">Input is case sensitive.</span>
      <b-input id="delete-custom-property-confirmation-box" v-model="typedName" />
      <br>
      <b-form-checkbox v-model="deleteFromRequirements" value="true">
        Remove this field from ALL REQUIREMENTS within this Specification.
      </b-form-checkbox>
      <p style="color: red; font-size: small; padding-top: 10px"><i><span class="font-weight-bolder">!Warning!</span> This can not be undone!</i>
      </p>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button id="delete-custom-property-button" :disabled="isBtnDisabled" size="sm" :variant="isBtnDisabled ? 'outline-danger' : 'danger'" @click="deleteCustomProperty()">
        Yes, delete it
      </b-button>
      <b-button size="sm" variant="secondary" @click="cancel()">
        Cancel, go back
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'DeleteCustomProperty',
  props: {
    customPropertyLabel: {
      type: String,
      required: true,
    },
    customPropertyValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isBtnDisabled: true,
      typedName: '',
      deleteFromRequirements: false,
      isLoading: false,
    }
  },
  watch: {
    typedName(val) {
      this.isBtnDisabled = val !== 'CONFIRM'
    },
  },
  methods: {
    deleteCustomProperty() {
      this.isLoading = true
      this.isBtnDisabled = true
      const customPropertyObject = { properties: {}, update_requirements: this.deleteFromRequirements }
      customPropertyObject.properties[this.customPropertyLabel] = this.customPropertyValue
      console.log('Deleting ', customPropertyObject)
      this.$store.dispatch('SpecificationPage/deleteCustomProperty', customPropertyObject).then(() => {
        this.isBtnDisabled = false
        this.$bvModal.hide('delete-custom-properties-modal')
        this.deleteFromRequirements = false
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
