<template>
  <b-modal
    id="action-review-modal"
    :title="`${action} Review`"
    centered
    @ok="actionReview"
    @shown="init"
  >
    <p>Please enter a reason for changing this Review to {{ action }}</p>
    <span class="font-weight-lighter text-secondary font-small-1">{{ action }} Reason.</span>
    <b-form-textarea v-model="reason" />
    <br>
    <!-- When closing/opening a review, check with the user whether the specification should be unlocked or not when implemented    -->
    <p>Please enter <span class="font-weight-bolder">CONFIRM</span> below to {{ action }}.</p>
    <span class="font-weight-lighter text-secondary font-small-1">Input is case sensitive.</span>
    <b-input v-model="typedName" />
    <template #modal-footer="{ ok, cancel }">
      <b-button :disabled="isBtnDisabled" size="sm" :variant="isBtnDisabled ? 'outline-danger' : 'danger'" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          {{ action }}ing Review...
        </span>
        <span v-else>
          {{ action }} Review
        </span>
      </b-button>
      <b-button size="sm" variant="secondary" @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ActionReview',
  props: {
    action: {
      type: String,
      required: true,
    },
    reviewObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBtnDisabled: true,
      typedName: '',
      reason: '',
      loading_status: false,
    }
  },
  watch: {
    typedName(val) {
      this.isBtnDisabled = val !== 'CONFIRM'
    },
  },
  methods: {
    init() {
      this.typedName = ''
      this.reason = ''
      this.loading_status = false
    },
    actionReview() {
      this.loading_status = true
      const payload = {
        reason: this.reason,
        action: this.action,
      }
      this.$http
        .put(`/api/v2/specifications/${this.reviewObject.specification_id}/approval/action`, payload)
        .then(({ data }) => {
          this.loading_status = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Review ${this.action}ed`,
              text: `This Review has now been ${this.action}ed.`,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('action-review-modal')
          this.$emit('refresh')
        })
        .catch(error => {
          this.loading_status = false
          console.error(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error attempting to change Review Status',
              text: `${error.response.data.detail}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>

</style>
