<template>
  <b-modal
    id="modal__UpdateCustomProperties"
    title="Update Custom Property"
    size="m"
    centered
    no-close-on-backdrop
    lazy
    @show="onShow"
    @submit="onSubmit"
  >
    <b-col v-if="loading" class="d-flex justify-content-start ml-1">
      <b-spinner
        small
        class="align-self-center mr-1"
      />
      Please wait...
    </b-col>
    <div v-if="!loading">
      <b-row>
        <b-col>
          <label>Property Label</label>
          <input v-model="propertyLabel" class="form-control" type="text">
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>Default Value</label>
          <input v-model="propertyValue" class="form-control" type="text">
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-form-checkbox v-model="updateValueInRequirements" v-b-tooltip.hover.right="'This will update all the Requirements within this Specification with this new Label and Default Value'" value="true">
          Update Existing Requirements
        </b-form-checkbox>
      </b-row>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button :disabled="loading" size="sm" :variant="loading ? 'outline-primary' : 'primary'" @click="onSubmit()">
        Update Property
      </b-button>
      <b-button size="sm" variant="secondary" @click="cancel()">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'UpdateCustomProperties',
  props: {
    customPropertyLabel: {
      type: String,
      required: true,
    },
    customPropertyValue: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const loading = ref(false)
    const propertyLabel = ref('')
    const propertyValue = ref('')
    const updateValueInRequirements = ref(false)

    const onShow = () => {
      propertyLabel.value = props.customPropertyLabel
      propertyValue.value = props.customPropertyValue
      loading.value = false
    }

    const onSubmit = () => {
      loading.value = true

      const payload = {
        change_from: {
          [props.customPropertyLabel]: props.customPropertyValue,
        },
        change_to: {
          [propertyLabel.value]: propertyValue.value,
        },
        update_requirement: updateValueInRequirements.value,
      }

      store
        .dispatch('SpecificationPage/updateCustomProperty', payload)
        .then(() => {
          context.root.$bvModal.hide('modal__UpdateCustomProperties')
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      loading,
      propertyLabel,
      propertyValue,
      updateValueInRequirements,
      onShow,
      onSubmit,
    }
  },
}
</script>
