<template>
  <b-modal
    id="delete-snapshot-modal"
    title="Delete Snapshot"
    centered
    @ok="deleteSnapshot"
  >
    <p>Are you sure you want to delete Snapshot <span class="font-weight-bolder">{{ snapshotObject.notes }}</span>?</p>
    <!--<pre>{{ snapshotObject }}</pre>-->
    <!--<b-card class="text-center" style="background-color: rgba(0, 0, 0, 0.1)">-->
    <!--  <div>-->
    <!--    <feather-icon-->
    <!--      icon="AlertTriangleIcon"-->
    <!--      class="mr-50 mb-25 text-danger"-->
    <!--    />-->
    <!--    WARNING: Deleting the Behaviour Tree will also delete all of the associated Behaviour Nodes.-->
    <!--  </div>-->
    <!--</b-card>-->

    <p>Please enter <span class="font-weight-bolder">CONFIRM</span> below to delete.</p>
    <span class="font-weight-lighter text-secondary font-small-1">Input is case sensitive.</span>
    <b-input id="delete-spec-snapshot-input-field" v-model="typedName" />
    <template #modal-footer="{ ok, cancel }">
      <b-button :disabled="isBtnDisabled" size="sm" :variant="isBtnDisabled ? 'outline-danger' : 'danger'" @click="ok()">
        Yes, delete it
      </b-button>
      <b-button size="sm" variant="secondary" @click="cancel()">
        Cancel, go back
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'DeleteSnapshot',
  props: {
    snapshotObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBtnDisabled: true,
      typedName: '',
    }
  },
  watch: {
    typedName(val) {
      this.isBtnDisabled = val !== 'CONFIRM'
    },
  },
  methods: {
    deleteSnapshot() {
      console.log('Deleting ', this.snapshotObject.id)
      const payload = {
        snapId: this.snapshotObject.id,
        specId: this.snapshotObject.specification_id,
      }
      this.$store.dispatch('SpecificationPage/deleteSnapshot', payload)
    },
  },
}
</script>

<style scoped>

</style>
